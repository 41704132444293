import type { IPayment } from "@/assets/ts/interfaces/payment/IPayment";
import type { IPaymentRepository } from "@/assets/ts/interfaces/payment/IPaymentRepository";
import type { Payment } from "@/assets/ts/models/payment/Payment";

export class PaymentRepository implements IPaymentRepository {
	constructor(private payment: Payment) {}

	get getPaymentData(): IPayment {
		return this.payment.data;
	}
}
